body {
  background-color: #000000;
  color: #ffffff;
}

.App {
  margin-top: 15px;
  margin-bottom: 15px;
}

.clock {
  margin: auto;
  white-space: nowrap;
  text-align: center;
}

.clock time {
  font-size: 5em;
  font-family: Orbitron;
}

#button-tray {
  margin: 15px;
}

/* Want room to fit all columns on small screen */
.col {
  padding-left: 0px;
}

div.notifications .card {
  background-color: #1b1e21;
  font-size: .7rem;
}

.card h5 {
  font-size: .8rem;
}

@media only screen and (min-width: 768px) {
    body {
      font-size: 1.6rem;
    }
    div.notifications .card {
      font-size: 1.2rem;
    }
    .card h5 {
      font-size: 1.4rem;
    }
}


div.notifications .card-footer {
  padding: .2rem;
}

/* Modal when you click a notification */
.modal-content {
  background-color: #1b1e21;
}
